import React from 'react';
import { GetServerSideProps } from 'next';
import { fetchContent } from '@/lib/contentful';
import Prehome, { PrehomeProps } from '@/components/templates/prehome';

export const getServerSideProps: GetServerSideProps = async ({ preview }) => {
  return {
    props: {
      page: await fetchContent({ contentType: 'home', id: 'prehome', preview }),
      footer: await fetchContent({
        contentType: 'globalFooter',
        id: 'footer-global',
        preview
      })
    }
  };
};

const Page = ({ page, footer }: PrehomeProps) => {
  return <Prehome page={page} footer={footer} pageView="prehome" />;
};

export default Page;
